/* eslint-disable import/prefer-default-export, react/prop-types */
import "./src/style.css"

export const wrapRootElement = ({ element }, options) => {
  return element;
};

export const wrapPageElement = ({ element, props }, options) => {
  return element;
};
